<template>
  <div class="page">
    <ul class="topNavDiv">
      <li v-show="item.status" v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}
        <div class="linear" v-if="clickIndex == item.type"></div>
      </li>
    </ul>
    <div class="btmDiv">
      <div class="formDiv">
        <BusinessLicenseInfo ref="businessLicenseInfo" @allform="getallform($event)" @sendsubfactoryID="getsubfactoryID($event)" @sendProduct="getProductForm($event)" @sendFillForm="getFillForm($event)" @sendsubfactory_id="getsubfactory_id($event)" @senddatmain="getdatmain($event)" @pull="getpull($event)" @sendform="getform($event)" v-show="clickIndex == 1"></BusinessLicenseInfo>
        <ProductLicense ref="productLicense" :datmain="datmain" @sendform="getform($event)" v-show="clickIndex == 2"></ProductLicense>
        <RegistrationCertificate ref="registrationCertificate" :datmain="datmain" @sendform="getform($event)" v-show="clickIndex == 3"></RegistrationCertificate>
      </div>
      <div class="saveDiv clearfix">
        <div v-show="this.data_maintenance == '1' && this.clickIndex == 2 && this.$route.query.status != 1" class="pageBtn finger btnSure fr marginRight18" type="primary" @click="isput = true">提交</div>
        <div v-show="this.data_maintenance == '2' && this.clickIndex == 3 && this.$route.query.status != 1" class="pageBtn finger btnSure fr marginRight18" type="primary" @click="isput = true">提交</div>
        <div v-show="this.data_maintenance == '3' && this.clickIndex == 3 && this.$route.query.status != 1" class="pageBtn finger btnSure fr marginRight18" type="primary" @click="isput = true">提交</div>
        <div v-show="((this.data_maintenance == '1' && this.clickIndex == 2)||(this.data_maintenance == '2' && this.clickIndex == 3)||(this.data_maintenance == '3' && this.clickIndex == 3)) && this.$route.query.status != 1" class="noticeText">(点击提交,将信息提交至管理端等待审核)</div>
        <div v-show="this.clickIndex == 1 && this.$route.query.status != 1" class="pageBtn finger btnSure fr marginRight18" type="primary" @click="nextstep1()">保存</div>
        <div v-show="this.clickIndex == 2 && this.$route.query.status != 1" class="pageBtn finger btnSure fr marginRight18" type="primary" @click="nextstep2()">保存</div>
        <div v-show="this.clickIndex == 3 && this.$route.query.status != 1" class="pageBtn finger btnSure fr marginRight18" type="primary" @click="nextstep3()">保存</div>
        <span class="pageBtn finger btnReset fr marginRight18" @click="back">返回</span>
      </div>
    </div>
    <!-- 启用模态框 -->
    <Modal v-model="isput" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>是否确认提交？</p>
        <div>
          <span class="pageBtn finger btnSure" @click="put()">确定</span>
          <span class="pageBtn finger btnCancle" @click="isput = false">取消</span>
        </div>
      </div>
    </Modal>
    <!-- 避免响应慢 没有效果 -->
    <Spin fix v-if="putLogin"></Spin> 
  </div>
</template>

<script>
import BusinessLicenseInfo from './businessLicenseInfo'
import ProductLicense from './productLicense'
import RegistrationCertificate from './registrationCertificate'
import { debounce } from "@/service/utils";
export default {
  name: 'manufacturerManage',
  components: {
    BusinessLicenseInfo,
    ProductLicense,
    RegistrationCertificate
  },
  computed: {
    totleMoney() {
      let totle = 0
      this.listData.forEach((e, i) => {
        totle = totle * 1 + e.money * 1
      })
      return totle
    }
  },
  mounted() {
    // this.getdatmain()
  },
  data() {
    return {
      putLogin: false,
      flag: true,
      subInfo: true,
      secendFactory_id: null,
      newfactoryId: null,
      isput: false,
      factory_id: null,
      subfactory_id: null,
      data_maintenance: '',
      factory_name: '',
      legal_person: '',
      address: '',
      factory_productlicense_id: '',
      filing_certificate_id: '',
      isLoad: false,
      navFrist: [
        {
          name: '营业执照信息',
          num: 140,
          type: 1,
          status: true
        },
        {
          name: '生产许可证',
          num: 40,
          type: 2,
          status: false
        },
        {
          name: '备案凭证',
          num: 40,
          type: 3,
          status: false
        }
      ],
      getfactory_id: null,
      clickIndex: 1,
      // 用来存储目前进行到的步骤
      currentIndex: 1,
      // 存储医院id
      ispromised1: false,
      // 控制跳转 和是否可选
      datmain: '0',
      form: '',
      putcontroal: true,
      isPull: false
    }
  },

  methods: {
    // 下拉触发事件
    getpull(data) {
      this.isPull = data
    },
    // 返回
    back() {
      this.$router.go(-1)
    },
    // 模版导入成功后获取数据
    getallform(e) {
      this.$refs.businessLicenseInfo.form = e.factory_business
      this.$refs.businessLicenseInfo.form.factory_type = '1'
      this.$refs.businessLicenseInfo.FactoryList.push({
        name: e.factory_business.factory_name
      })
      this.$refs.businessLicenseInfo.form.product = '1'
      this.$refs.businessLicenseInfo.form.fill = '1'

      this.$refs.productLicense.form = e.factory_product
      this.$refs.registrationCertificate.form = e.factory_filing
      //
      // this.navFrist.forEach((item, index) => {
      //   item.status = true
      // })
    },
    // 下拉 传递subfactory_id
    getsubfactoryID(e) {
      console.log(e)
      // 如果不是undefined就值赋予
      if (e !== undefined) {
        this.subfactory_id = e
      }
    },
    // 获取生产许可信息
    getProductForm(e) {
      this.$refs.productLicense.form.productionlicense_file_name = e.productionlicense_file_name
      this.$refs.productLicense.form.certification_department = e.certification_department
      this.$refs.productLicense.form.license_number = e.license_number
      this.$refs.productLicense.form.factory_name = e.product_factory_name
      this.$refs.productLicense.form.legal_person = e.product_legal_person
      this.$refs.productLicense.form.production_address = e.product_production_address
      this.$refs.productLicense.form.production_range = e.product_production_range
      this.$refs.productLicense.form.residence = e.product_residence
      this.$refs.productLicense.form.responsible_person = e.product_responsible_person
      this.$refs.productLicense.form.productionlicense_file_name = e.productionlicense_file_name
      this.$refs.productLicense.form.productionlicense_key = e.productionlicense_key
      this.$refs.productLicense.form.productionlicense_url = e.productionlicense_url
      this.$refs.productLicense.form.certification_date = this.$moment.unix(e.certification_date).format('YYYY-MM-DD')
      if (this.$refs.productLicense.form.certification_date == '1970-01-01') {
        this.$refs.productLicense.form.certification_date = null
      }
      this.$refs.productLicense.form.effective_end_date = this.$moment.unix(e.effective_end_date).format('YYYY-MM-DD')
      this.$refs.productLicense.form.effective_start_date = this.$moment.unix(e.effective_start_date).format('YYYY-MM-DD')
    },
    // 获取备案凭证信息
    getFillForm(e) {
      this.$refs.registrationCertificate.form.filing_certificate_key = e.filing_certificate_key
      this.$refs.registrationCertificate.form.filing_file_name = e.filing_file_name
      this.$refs.registrationCertificate.form.filing_certificate_url = e.filing_certificate_url
      this.$refs.registrationCertificate.form.filing_department = e.filing_department
      this.$refs.registrationCertificate.form.factory_name = e.filing_factory_name
      this.$refs.registrationCertificate.form.legal_person = e.filing_legal_person
      this.$refs.registrationCertificate.form.filing_number = e.filing_number
      this.$refs.registrationCertificate.form.production_address = e.filing_production_address
      this.$refs.registrationCertificate.form.production_range = e.filing_production_range
      this.$refs.registrationCertificate.form.residence = e.filing_residence
      this.$refs.registrationCertificate.form.responsible_person = e.filing_responsible_person
      this.$refs.registrationCertificate.form.filing_date = this.$moment.unix(e.filing_date).format('YYYY-MM-DD')
    },
    getsubfactory_id(e) {
      this.subfactory_id = e
    },
    getdatmain(data) {
      this.datmain = data
      if (this.datmain == '1') {
        this.data_maintenance = '1'
        this.navFrist[1].status = true
        this.navFrist[2].status = false
      }
      if (this.datmain == '2') {
        this.data_maintenance = '2'
        this.navFrist[1].status = false
        this.navFrist[2].status = true
      }
      if (this.datmain == '3') {
        this.data_maintenance = '3'
        this.navFrist.forEach((item, index) => {
          item.status = true
        })
      }
      if (this.isPull) {
        this.navFrist[1].status = false
        this.navFrist[2].status = false
      }
    },
    clickNav(type) {
      // 隐藏下面的 if  打开吓一条 赋值可实现随意点击切换
      this.clickIndex = type
      // 加上判断条件 判断 点击tab是否切换
      // if (this.datmain == '3') {
      //   this.clickIndex = type
      // }
      // if (this.datmain == '2' && type != 2) {
      //   this.clickIndex = type
      // }
      // if (this.datmain == '1' && type != 3) {
      //   this.clickIndex = type
      // }
    },
    getform(data) {
      this.clickIndex = data
      this.currentIndex = data
    },
    // 营业执照信息保存
    nextstep1() {
      this.form = this.$refs.businessLicenseInfo.form
      if (this.secendFactory_id != null) {
        this.form.factory_id = this.secendFactory_id
      }
      // 判断资料维护条件
      if(this.form.product == '0' && this.form.fill == '0') {
        this.$Message.warning('请至少选择一项资料维护')
        return
      }
      // 如果只选择了生产许可
      if (this.form.product == '1' && this.form.fill == '1') {
        this.form.data_maintenance = '3'
        this.data_maintenance = '3'
        this.$emit('senddatmain', '3')
      }
      // 如果只选则了备案凭证
      if (this.form.product == '0' && this.form.fill == '1') {
        this.form.data_maintenance = '2'
        this.data_maintenance = '2'
        this.$emit('senddatmain', '2')
      }
      // 如果两个都选择了
      if (this.form.product == '1' && this.form.fill == '0') {
        this.form.data_maintenance = '1'
        this.data_maintenance = '1'
        this.$emit('senddatmain', '1')
      }
      let postform = {
        // 下拉才会有subfactory_id
        subfactory_id: this.form.subfactory_id,
        // factory_id  编辑进入保存才有
        factory_id: this.$route.query.id || this.factory_id || this.form.factory_id,
        factory_name: this.form.factory_name, //	工厂姓名
        businesslicense_file_name: this.form.businesslicense_file_name,
        businesslicense_key: this.form.businesslicense_key, // 营业执照Key
        businesslicense_url: this.form.businesslicense_url, // 营业执照Key
        contract_file_name: this.form.contract_file_name,
        contract_key: this.form.contract_key, // 合同KEY
        contract_url: this.form.contract_url, // 合同KEY
        social_credit_code: this.form.social_credit_code, // 统一社会信用代码
        is_long_term: this.form.is_long_term, // 是否长期 1是 0否
        detailed_address: this.form.detailed_address, // 详情地址
        legal_person: this.form.legal_person, // 法人
        registered_capital: this.form.registered_capital, // 注册资本
        setup_date: this.form.setup_date, // 成立日期
        registered_date: this.form.registered_date, // 登记日期
        business_start: this.form.business_start, // 起始营业期限
        business_end: this.form.business_end, // 截止营业期限
        business_range: this.form.business_range, // 经营范围
        factory_type: this.form.factory_type, // 厂家类型 1=国内厂家 2=境外厂家 0=未知
        data_maintenance: this.form.data_maintenance // 维护状态
      }
      if (postform.factory_id == '') {
        this.factory_id = null
      }
      let data = postform
      // ------------对象 提交前 添加判定条件----------
      // 时间检验
      if (!data.business_start) {
        data.business_start = null
      }
      if (!data.business_end) {
        data.business_end = null
      }
      if (!data.setup_date) {
        data.setup_date = null
      }
      if (!data.registered_date) {
        data.registered_date = null
      }

      // ------------对象 提交前 添加判定条件----------

      // 判断  data_maintenance

      // -------------------验证规则-----------------
      if (!data.businesslicense_key || !data.businesslicense_file_name || !data.businesslicense_url) {
        this.$Message.warning('请上传营业执照')
        return
      }
      if (!data.businesslicense_key) {
        this.$Message.warning('请上传营业执照')
        return
      }
      if (!data.factory_name) {
        this.$Message.warning('请填写公司名称')
        return
      }
      if (!data.social_credit_code) {
        this.$Message.warning('请填写统一社会信用')
        return
      }
      if (!data.detailed_address) {
        this.$Message.warning('请填写住所')
        return
      }
      if (!data.legal_person) {
        this.$Message.warning('请填写法人')
        return
      }
      if (!data.registered_capital) {
        this.$Message.warning('请选择注册资本')
        return
      }
      console.log(this.$refs.businessLicenseInfo)
      // 如果不是长期
      if (data.is_long_term == '0') {
        if (!data.business_start) {
          this.$Message.warning('请填写起始营业期限')
          return
        }
        if (!data.business_end) {
          this.$Message.warning('请填写截止营业期限')
          return
        }
      }
      // 如果是长期
      if (data.is_long_term == '1') {
        if (!data.business_start) {
          this.$Message.warning('请填写起始营业期限')
          return
        }
      }
      if (!data.business_range) {
        this.$Message.warning('请填写经营范围')
        return
      }
      if (!data.data_maintenance) {
        this.$Message.warning('请选择资料维护')
        return
      }
      // ----------------------验证规则-------------------
      // 发送保存请求
      if (!this.flag) return
      this.flag = false
      this.$http.post(this.$api.factoryManage, data, true).then(res => {
        // 存着需要用的数据
        this.flag = true
        this.factory_productlicense_id = res.data.factory_productlicense_id
        this.filing_certificate_id = res.data.factory_productlicense_id
        this.secendFactory_id = res.data.factory_id
        this.$root.eventHub.$emit('sendIDToReg', res.data.factory_id)
        this.$root.eventHub.$emit('sendDatatoProdut', res.data.factory_id, res.data.factory_name, res.data.legal_person, res.data.address, res.data.factory_productlicense_id)
        this.$root.eventHub.$emit('sendDatatoRegist', res.data.factory_id, res.data.factory_name, res.data.legal_person, res.data.address, res.data.filing_certificate_id)
        // 如果保存了
        if (this.form.data_maintenance == '1') {
          this.data_maintenance = '1'
          if (this.putcontroal == true) {
            this.$Message.success('保存成功')
          }
          this.$root.eventHub.$emit('sendDatamainPoduct', this.form.data_maintenance)
          this.clickIndex = 2
          this.navFrist[1].status = true
          this.navFrist[2].status = false
        }
        if (this.form.data_maintenance == '2') {
          this.data_maintenance = '2'
          if (this.putcontroal == true) {
            this.$Message.success('保存成功')
          }
          this.$root.eventHub.$emit('sendDatamainRegistra', this.form.data_maintenance)
          this.clickIndex = 3
          this.navFrist[1].status = false
          this.navFrist[2].status = true
        }
        if (this.form.data_maintenance == '3') {
          this.data_maintenance = '3'
          if (this.putcontroal == true) {
            this.$Message.success('保存成功')
          }
          this.clickIndex = 2
          this.navFrist[1].status = true
          this.navFrist[2].status = true
        }
      }).catch((err) =>{
        this.flag = true
      })
      sessionStorage.setItem('updateList','0')
    },
    // 生产许可保存
    nextstep2() {

      // 获取生产许可form对象
      this.form = this.$refs.productLicense.form
      let data = {
        subfactory_id: this.subfactory_id,
        factory_id: this.$refs.productLicense.factory_id || this.factory_id,
        factory_productlicense_id: this.$refs.productLicense.factory_productlicense_id,
        productionlicense_file_name: this.form.productionlicense_file_name,
        productionlicense_key: this.form.productionlicense_key,
        productionlicense_url: this.form.productionlicense_url,
        license_number: this.form.license_number,
        factory_name: this.form.factory_name,
        production_address: this.form.production_address,
        residence: this.form.residence,
        legal_person: this.form.legal_person,
        responsible_person: this.form.responsible_person,
        effective_start_date: this.form.effective_start_date,
        effective_end_date: this.form.effective_end_date,
        production_range: this.form.production_range,
        certification_department: this.form.certification_department,
        certification_date: this.form.certification_date
      }
      // 校验生产许可信息

      if (!data.certification_date) {
        delete data.certification_date
      }
      if (!data.productionlicense_key || !data.productionlicense_file_name || !data.productionlicense_url) {
        this.$Message.warning('请上传生产许可证')
        return
      }
      if (!data.effective_start_date) {
        this.$Message.warning('请填起始营业期限')
        return
      }
      if (!data.effective_end_date) {
        this.$Message.warning('请填起始截止期限')
        return
      }
      if (!data.license_number) {
        this.$Message.warning('请填写许可证编号')
        return
      }
      if (!data.factory_name) {
        this.$Message.warning('请填写企业名称')
        return
      }
      if (!data.legal_person) {
        this.$Message.warning('请填写法人')
        return
      }
      if (!data.responsible_person) {
        this.$Message.warning('请填写企业负责人')
        return
      }
      if (!data.production_address) {
        this.$Message.warning('请填写生产地址')
        return
      }
      if (!data.residence) {
        this.$Message.warning('请填写住所')
        return
      }
      if (!data.production_range) {
        this.$Message.warning('请填写生产范围')
        return
      }
      console.log(data)
      if (!this.flag) return
      this.flag = false
      this.$http.post(this.$api.factoryManageProductionlicense, data, true).then(res => {
        this.flag = true
        if (this.putcontroal == true) {
          if (this.putcontroal == true) {
            this.$Message.success('保存成功')
          }
          if (this.data_maintenance == '1') {
            this.$router.push('/factoryManage')
          }
          if (this.data_maintenance == '3') {
            this.clickIndex = 3
          }
        }
      }).catch((err) =>{
        this.flag = true
      })
      sessionStorage.setItem('updateList','0')
    },
    // 备案凭证保存
    nextstep3() {

      // 校验营业执照信息
      let form2 = this.$refs.businessLicenseInfo.form
      if (!form2.businesslicense_key || !form2.businesslicense_file_name || !form2.businesslicense_url) {
        this.$Message.warning('请上传营业执照')
        return
      }
      if (!form2.factory_name) {
        this.$Message.warning('请填写公司名称')
        return
      }
      if (!form2.social_credit_code) {
        this.$Message.warning('请填写统一社会信用')
        return
      }
      if (!form2.detailed_address) {
        this.$Message.warning('请填写住所')
        return
      }
      if (!form2.legal_person) {
        this.$Message.warning('请填写法人')
        return
      }
      if (!form2.registered_capital) {
        this.$Message.warning('请选择注册资本')
        return
      }
      // 如果不是长期
      if (form2.is_long_term == '0') {
        if (!form2.business_start) {
          this.$Message.warning('请填写起始营业期限')
          return
        }
        if (!form2.business_end) {
          this.$Message.warning('请填写截止营业期限')
          return
        }
      }
      // 如果是长期
      if (form2.is_long_term == '1') {
        if (!form2.business_start) {
          this.$Message.warning('请填写起始营业期限')
          return
        }
      }
      if (!form2.business_range) {
        this.$Message.warning('请填写经营范围')
        return
      }
      if (!form2.data_maintenance) {
        this.$Message.warning('请选择资料维护')
        return
      }

      // 如果只选择生产许可  就校验生产许可信息
      if (this.data_maintenance == '3') {
        let form1 = this.$refs.productLicense.form
        if (!form1.certification_date) {
          delete form1.certification_date
        }
        if (!form1.productionlicense_key || !form1.productionlicense_file_name || !form1.productionlicense_url) {
          this.$Message.warning('请上传生产许可证')
          return
        }
        if (!form1.effective_start_date) {
          this.$Message.warning('请填起始营业期限')
          return
        }
        if (!form1.effective_end_date) {
          this.$Message.warning('请填起始截止期限')
          return
        }
        if (!form1.license_number) {
          this.$Message.warning('请填写许可证编号')
          return
        }
        if (!form1.factory_name) {
          this.$Message.warning('请填写企业名称')
          return
        }
        if (!form1.legal_person) {
          this.$Message.warning('请填写法人')
          return
        }
        if (!form1.responsible_person) {
          this.$Message.warning('请填写企业负责人')
          return
        }
        if (!form1.production_address) {
          this.$Message.warning('请填写生产地址')
          return
        }
        if (!form1.residence) {
          this.$Message.warning('请填写住所')
          return
        }
        if (!form1.production_range) {
          this.$Message.warning('请填写生产范围')
          return
        }
      }

      this.form = this.$refs.registrationCertificate.form
      let data = {
        subfactory_id: this.subfactory_id,
        filing_certificate_id: this.$refs.registrationCertificate.filing_certificate_id, // 备案凭证
        factory_id: this.$refs.registrationCertificate.factory_id || this.$route.query.id || this.$refs.businessLicenseInfo.newfactoryId, // 工厂ID
        filing_file_name: this.form.filing_file_name,
        filing_certificate_key: this.form.filing_certificate_key, // 备案凭证KEY
        filing_certificate_url: this.form.filing_certificate_url, // 备案凭证KEY
        filing_number: this.form.filing_number, //	备案号
        factory_name: this.form.factory_name, // 工厂姓名
        production_address: this.form.production_address, //	生产地址
        residence: this.form.residence, // 住所
        legal_person: this.form.legal_person, //	法人
        responsible_person: this.form.responsible_person, // 企业负责人
        production_range: this.form.production_range, // 生产范围
        filing_department: this.form.filing_department, // 备案部门
        filing_date: this.form.filing_date // 备案日期
      }

      // 校验备案凭证信息
      if (data.filing_date == '') {
        delete data.filing_date
      }
      if (!data.filing_certificate_key || !data.filing_file_name || !data.filing_certificate_url) {
        this.$Message.warning('请上传备案凭证')
        return
      }
      if (!data.filing_number) {
        this.$Message.warning('请填写备案号')
        return
      }
      if (!data.factory_name) {
        this.$Message.warning('请填写企业名称')
        return
      }
      if (!data.residence) {
        this.$Message.warning('请填写住所')
        return
      }
      if (!data.legal_person) {
        this.$Message.warning('请填写法人')
        return
      }

      if (!data.responsible_person) {
        this.$Message.warning('请填写负责人')
        return
      }
      if (!data.production_address) {
        this.$Message.warning('请填写生产地址')
        return
      }
      if (!data.production_range) {
        this.$Message.warning('请填写生产范围')
        return
      }
      if (!data.filing_date) {
        this.$Message.warning('请填写备案日期')
        return
      }
      // -----------------验证规则-------------------
      if (!this.flag) return
      this.flag = false
      this.$http.post(this.$api.factoryManageFilingcertificate, data, true).then(res => {
        this.flag = true
        if (this.putcontroal == true) {
          this.$Message.success('保存成功')
          this.$router.push('/factoryManage')
        }
      }).catch((err) =>{
        this.flag = true
      })
      sessionStorage.setItem('updateList','0')
    },
    // 提交
    put:debounce(function() {

      let postFrom1 = this.$refs.businessLicenseInfo.form
      let postFrom2 = this.$refs.productLicense.form
      let postFrom3 = this.$refs.registrationCertificate.form
      // 提交规则验证
      console.log(this.data_maintenance)
      // 如果只选择生产许可
      if (this.data_maintenance == '1') {
        if (!postFrom1.businesslicense_key || !postFrom1.businesslicense_file_name || !postFrom1.businesslicense_url) {
          this.$Message.warning('请上传营业执照')
          return
        }
        if (!postFrom1.factory_name) {
          this.$Message.warning('请填写公司名称')
          this.isput = false
          return
        }
        if (!postFrom1.social_credit_code) {
          this.$Message.warning('请填写统一社会信用')
          this.isput = false
          return
        }
        if (!postFrom1.legal_person) {
          this.$Message.warning('请填写法人')
          this.isput = false
          return
        }
        if (!postFrom1.registered_capital) {
          this.$Message.warning('请选择注册资本')
          this.isput = false
          return
        }
        if (!postFrom1.business_range) {
          this.$Message.warning('请填写经营范围')
          this.isput = false
          return
        }
        if (!postFrom1.data_maintenance) {
          this.$Message.warning('请选择资料维护')
          this.isput = false
          return
        }
        if (!postFrom2.productionlicense_key || !postFrom2.productionlicense_file_name || !postFrom2.productionlicense_url) {
          this.$Message.warning('请上传生产许可证')
          return
        }
        if (!postFrom2.effective_start_date) {
          this.$Message.warning('请填起始营业期限')
          this.isput = false
          return
        }
        if (!postFrom2.effective_end_date) {
          this.$Message.warning('请填截止营业期限')
          this.isput = false
          return
        }
        if (!postFrom2.license_number) {
          this.$Message.warning('请填写许可编号')
          this.isput = false
          return
        }
        if (!postFrom2.factory_name) {
          this.$Message.warning('请填写企业名称')
          this.isput = false
          return
        }
        if (!postFrom2.legal_person) {
          this.$Message.warning('请填写法人')
          this.isput = false
          return
        }
        if (!postFrom2.responsible_person) {
          this.$Message.warning('请填写企业负责人')
          this.isput = false
          return
        }
        if (!postFrom2.production_address) {
          this.$Message.warning('请填写生产地址')
          this.isput = false
          return
        }
        if (!postFrom2.residence) {
          this.$Message.warning('请填写住所')
          this.isput = false
          return
        }
        if (!postFrom2.production_range) {
          this.$Message.warning('请填写生产范围')
          return
        }
      }

      // 如果只选择备案凭证
      if (this.data_maintenance == '2') {
        if (!postFrom1.businesslicense_key || !postFrom1.businesslicense_file_name || !postFrom1.businesslicense_url) {
          this.$Message.warning('请上传营业执照')
          return
        }
        if (!postFrom1.factory_name) {
          this.$Message.warning('请填写公司名称')
          this.isput = false
          return
        }
        if (!postFrom1.social_credit_code) {
          this.$Message.warning('请填写统一社会信用')
          this.isput = false
          return
        }
        if (!postFrom1.legal_person) {
          this.$Message.warning('请填写法人')
          this.isput = false
          return
        }
        if (!postFrom1.registered_capital) {
          this.$Message.warning('请选择注册资本')
          this.isput = false
          return
        }
        if (!postFrom1.business_range) {
          this.$Message.warning('请填写经营范围')
          this.isput = false
          return
        }
        if (!postFrom1.data_maintenance) {
          this.$Message.warning('请选择资料维护')
          this.isput = false
          return
        }
        if (!postFrom3.filing_certificate_key || !postFrom3.filing_file_name || !postFrom3.filing_certificate_url) {
          this.$Message.warning('请上传备案凭证')
          return
        }
        if (!postFrom3.filing_number) {
          this.$Message.warning('请填写备案号')
          this.isput = false
          return
        }
        if (!postFrom3.factory_name) {
          this.$Message.warning('请填写企业名称')
          this.isput = false
          return
        }
        if (!postFrom3.residence) {
          this.$Message.warning('请填写住所')
          this.isput = false
          return
        }
        if (!postFrom3.legal_person) {
          this.$Message.warning('请填写法人')
          this.isput = false
          return
        }

        if (!postFrom3.responsible_person) {
          this.$Message.warning('请填写负责人')
          this.isput = false
          return
        }
        if (!postFrom3.production_address) {
          this.$Message.warning('请填写生产地址')
          this.isput = false
          return
        }
        if (!postFrom3.production_range) {
          this.$Message.warning('请填写生产范围')
          this.isput = false
          return
        }
        if (!postFrom3.filing_date) {
          this.$Message.warning('请填写备案日期')
          this.isput = false
          return
        }
      }

      // 如果生产许可 备案凭证都有
      if (this.data_maintenance == '3') {
        if (!postFrom1.businesslicense_key || !postFrom1.businesslicense_file_name || !postFrom1.businesslicense_url) {
          this.$Message.warning('请上传营业执照')
          return
        }
        if (!postFrom1.factory_name) {
          this.$Message.warning('请填写公司名称')
          this.isput = false
          return
        }
        if (!postFrom1.social_credit_code) {
          this.$Message.warning('请填写统一社会信用')
          this.isput = false
          return
        }
        if (!postFrom1.legal_person) {
          this.$Message.warning('请填写法人')
          this.isput = false
          return
        }
        if (!postFrom1.registered_capital) {
          this.$Message.warning('请选择注册资本')
          this.isput = false
          return
        }
        if (!postFrom1.business_range) {
          this.$Message.warning('请填写经营范围')
          this.isput = false
          return
        }
        if (!postFrom1.data_maintenance) {
          this.$Message.warning('请选择资料维护')
          this.isput = false
          return
        }
        if (!postFrom2.productionlicense_key || !postFrom2.productionlicense_file_name || !postFrom2.productionlicense_url) {
          this.$Message.warning('请上传生产许可证')
          return
        }
        if (!postFrom2.effective_start_date) {
          this.$Message.warning('请填起始营业期限')
          this.isput = false
          return
        }
        if (!postFrom2.effective_end_date) {
          this.$Message.warning('请填截止营业期限')
          this.isput = false
          return
        }
        if (!postFrom2.license_number) {
          this.$Message.warning('请填写许可编号')
          this.isput = false
          return
        }
        if (!postFrom2.factory_name) {
          this.$Message.warning('请填写企业名称')
          this.isput = false
          return
        }
        if (!postFrom2.legal_person) {
          this.$Message.warning('请填写法人')
          this.isput = false
          return
        }
        if (!postFrom2.responsible_person) {
          this.$Message.warning('请填写企业负责人')
          this.isput = false
          return
        }
        if (!postFrom2.production_address) {
          this.$Message.warning('请填写生产地址')
          this.isput = false
          return
        }
        if (!postFrom2.residence) {
          this.$Message.warning('请填写住所')
          this.isput = false
          return
        }
        if (!postFrom3.filing_certificate_key || !postFrom3.filing_file_name || !postFrom3.filing_certificate_url) {
          this.$Message.warning('请上传备案凭证')
          return
        }
        if (!postFrom3.filing_number) {
          this.$Message.warning('请填写备案号')
          this.isput = false
          return
        }
        if (!postFrom3.factory_name) {
          this.$Message.warning('请填写企业名称')
          this.isput = false
          return
        }
        if (!postFrom3.residence) {
          this.$Message.warning('请填写住所')
          this.isput = false
          return
        }
        if (!postFrom3.legal_person) {
          this.$Message.warning('请填写法人')
          this.isput = false
          return
        }

        if (!postFrom3.responsible_person) {
          this.$Message.warning('请填写负责人')
          this.isput = false
          return
        }
        if (!postFrom3.production_address) {
          this.$Message.warning('请填写生产地址')
          this.isput = false
          return
        }
        if (!postFrom3.production_range) {
          this.$Message.warning('请填写生产范围')
          this.isput = false
          return
        }
        if (!postFrom3.filing_date) {
          this.$Message.warning('请填写备案日期')
          this.isput = false
          return
        }
      }

      this.form1 = this.$refs.businessLicenseInfo.form
      let postform = {
        // 下拉才会有subfactory_id
        subfactory_id: this.subfactory_id,
        // factory_id  编辑进入保存才有
        factory_id: this.$route.query.id || this.factory_id || null || this.$refs.businessLicenseInfo.ImportantId,
        factory_name: this.form1.factory_name, //	工厂姓名
        businesslicense_file_name: this.form1.businesslicense_file_name,
        contract_file_name: this.form1.contract_file_name,
        businesslicense_key: this.form1.businesslicense_key, // 营业执照Key
        contract_key: this.form1.contract_key, // 合同KEY
        social_credit_code: this.form1.social_credit_code, // 统一社会信用代码
        is_long_term: this.form1.is_long_term, // 是否长期 1是 0否
        detailed_address: this.form1.detailed_address, // 详情地址
        legal_person: this.form1.legal_person, // 法人
        registered_capital: this.form1.registered_capital, // 注册资本
        setup_date: this.form1.setup_date, // 成立日期
        registered_date: this.form1.registered_date, // 登记日期
        business_start: this.form1.business_start, // 起始营业期限
        business_end: this.form1.business_end, // 截止营业期限
        business_range: this.form1.business_range, // 经营范围
        factory_type: this.form1.factory_type, // 厂家类型 1=国内厂家 2=境外厂家 0=未知
        data_maintenance: this.form1.data_maintenance // 维护状态
      }
      let data1 = postform
      // ------------对象 提交前 添加判定条件----------
      // 时间检验
      if (data1.business_start == '') {
        data1.business_start = null
      }
      if (data1.business_end == '') {
        data1.business_end = null
      }
      if (data1.setup_date == '') {
        data1.setup_date = null
      }
      if (data1.registered_date == '') {
        data1.registered_date = null
      }

      // ------------对象 提交前 添加判定条件----------

      // -------------------验证规则-----------------
      if (data1.factory_name == '') {
        this.$Message.warning('请填写公司名称')
        return
      }
      if (data1.social_credit_code == '') {
        this.$Message.warning('请填写统一社会信用')
        return
      }
      if (data1.legal_person == '') {
        this.$Message.warning('请填写法人')
        return
      }
      if (data1.registered_capital == '') {
        this.$Message.warning('请选择注册资本')
        return
      }
      if (data1.business_range == '') {
        this.$Message.warning('请填写经营范围')
        return
      }
      if (data1.data_maintenance == '') {
        this.$Message.warning('请选择资料维护')
        return
      }
      this.putLogin = true
      // ----------------------验证规则-------------------
      if(!this.subInfo) {
         return
      }
      this.subInfo = false
      
      // 发送保存请求
      this.$http
        .post(this.$api.factoryManage, data1, true)
        .then(res => {
          // ---------------生产许可证---------------
          if (this.data_maintenance == '1' || this.data_maintenance == '3') {
            this.form2 = this.$refs.productLicense.form
            let data2 = {
              subfactory_id: this.subfactory_id,
              factory_id: this.$refs.registrationCertificate.factory_id || this.$route.query.id || this.factory_id,
              factory_productlicense_id: this.$refs.productLicense.factory_productlicense_id || this.factory_productlicense_id,
              productionlicense_file_name: this.form2.productionlicense_file_name,
              productionlicense_key: this.form2.productionlicense_key,
              license_number: this.form2.license_number,
              factory_name: this.form2.factory_name,
              production_address: this.form2.production_address,
              residence: this.form2.residence,
              legal_person: this.form2.legal_person,
              responsible_person: this.form2.responsible_person,
              effective_start_date: this.form2.effective_start_date,
              effective_end_date: this.form2.effective_end_date,
              production_range: this.form2.production_range,
              certification_department: this.form2.certification_department,
              certification_date: this.form2.certification_date
            }
            console.log(data2)
            // if (data2.certification_date === '') {
            //   data2.certification_date = null
            // }
            this.$http.post(this.$api.factoryManageProductionlicense, data2, true).then(res => {
              
            })
          }
        })
        .then(res => {
          // ---------------备案凭证-----------
          if (this.data_maintenance == '3' || this.data_maintenance == '2') {
            // this.form3 = this.$refs.registrationCertificate.form
            // this.getfactory_id控制 下拉用户的id
            this.getfactory_id = this.$refs.registrationCertificate.getfactory_id
            this.form3 = this.$refs.registrationCertificate.form
            let data3 = {
              subfactory_id: this.subfactory_id,
              filing_certificate_id: this.$refs.registrationCertificate.filing_certificate_id || this.filing_certificate_id, // 备案凭证
              factory_id: this.$refs.businessLicenseInfo.ImportantId || this.$route.query.id || this.factory_id, // 工厂ID
              filing_file_name: this.form3.filing_file_name,
              filing_certificate_key: this.form3.filing_certificate_key, // 备案凭证KEY
              filing_certificate_url: this.form3.filing_certificate_url, // 备案凭证KEY
              filing_number: this.form3.filing_number, //	备案号
              factory_name: this.form3.factory_name, // 工厂姓名
              production_address: this.form3.production_address, //	生产地址
              residence: this.form3.residence, // 住所
              legal_person: this.form3.legal_person, //	法人
              responsible_person: this.form3.responsible_person, // 企业负责人
              production_range: this.form3.production_range, // 生产范围
              filing_department: this.form3.filing_department, // 备案部门
              filing_date: this.form3.filing_date // 备案日期
            }
            if (data3.filing_date == '') {
              delete data3.filing_date
            }
            // ---------------表单对象提交为空检验-------------

            // -----------------验证规则-------------------
            this.$http.post(this.$api.factoryManageFilingcertificate, data3, true).then(res => {})
          }
        })
        .then(res => {
          // ----------------------发送保存请求------------------------
          this.isput = false
          let postFrom1 = this.$refs.businessLicenseInfo.form
          let postFrom2 = this.$refs.productLicense.form
          let postFrom3 = this.$refs.registrationCertificate.form

          let data = {
            // 有就穿没有不穿
            // this.$route.query.subfactory_id||
            subfactory_id: this.$route.query.subfactory_id || this.subfactory_id,
            // 营业执照中的内容
            factory_id: this.$refs.businessLicenseInfo.ImportantId || this.factory_id || this.$route.query.id,
            factory_name: postFrom1.factory_name,
            businesslicense_key: postFrom1.businesslicense_key,
            businesslicense_file_name: postFrom1.businesslicense_file_name,
            contract_key: postFrom1.contract_key,
            contract_file_name: postFrom1.contract_file_name,
            data_maintenance: postFrom1.data_maintenance,
            detailed_address: postFrom1.detailed_address,
            social_credit_code: postFrom1.social_credit_code, /// ///
            business_range: postFrom1.business_range,
            business_start: postFrom1.business_start,
            business_end: postFrom1.business_end,
            is_supplier: postFrom1.is_supplier,
            is_long_term: postFrom1.is_long_term,
            legal_person: postFrom1.legal_person,
            province: postFrom1.province,
            registered_capital: postFrom1.registered_capital,
            registered_date: postFrom1.registered_date,
            setup_date: postFrom1.setup_date,
            factory_type: postFrom1.factory_type, // 厂家类型 1=国内厂家 2=境外厂家 0=未知

            // 生产许可中的内容
            // 备案凭证form
          }
          // this.navFrist.forEach((item,index) => {
            if(this.data_maintenance == '1') {
              //  this.bnpz = true
              let infos = {
                certification_date: postFrom2.certification_date,
                certification_department: postFrom2.certification_department,
                effective_end_date: postFrom2.effective_end_date,
                effective_start_date: postFrom2.effective_start_date,
                product_factory_name: postFrom2.factory_name,
                product_legal_person: postFrom2.legal_person,
                license_number: postFrom2.license_number,
                product_production_address: postFrom2.production_address,
                product_production_range: postFrom2.production_range,
                productionlicense_file_name: postFrom2.productionlicense_file_name,
                productionlicense_key: postFrom2.productionlicense_key,
                product_residence: postFrom2.residence,
                product_responsible_person: postFrom2.responsible_person,
                filing_certificate_key: null,
                filing_certificate_url: null,
                filing_date: null,
                filing_file_name: null,
                filing_number: null,
                filing_factory_name: null,
                filing_production_address: null,
                filing_residence: null,
                filing_legal_person: null,
                filing_responsible_person: null,
                filing_production_range: null,
                filing_department: null
              }
              
            data = Object.assign({},data,infos)
            } else if (this.data_maintenance == '2') {
            let info = {

            certification_date: null,
            certification_department: null,
            effective_end_date: null,
            effective_start_date: null,
            product_factory_name: null,
            product_legal_person: null,
            license_number: null,
            product_production_address: null,
            product_production_range: null,
            productionlicense_file_name: null,
            productionlicense_key: null,
            product_residence: null,
            product_responsible_person: null,

            filing_certificate_key: postFrom3.filing_certificate_key,
            filing_certificate_url: postFrom3.filing_certificate_url || null,
            filing_date: postFrom3.filing_date || null,
            filing_file_name: postFrom3.filing_file_name,
            filing_number: postFrom3.filing_number,
            filing_factory_name: postFrom3.factory_name,
            filing_production_address: postFrom3.production_address,
            filing_residence: postFrom3.residence,
            filing_legal_person: postFrom3.legal_person,
            filing_responsible_person: postFrom3.responsible_person,
            filing_production_range: postFrom3.production_range,
            filing_department: postFrom3.filing_department
              }
             data = Object.assign({},data,info)
            } else if (this.data_maintenance == '3') {
              let info3 = {
                filing_certificate_key: postFrom3.filing_certificate_key,
                filing_certificate_url: postFrom3.filing_certificate_url || null,
                filing_date: postFrom3.filing_date || null,
                filing_file_name: postFrom3.filing_file_name,
                filing_number: postFrom3.filing_number,
                filing_factory_name: postFrom3.factory_name,
                filing_production_address: postFrom3.production_address,
                filing_residence: postFrom3.residence,
                filing_legal_person: postFrom3.legal_person,
                filing_responsible_person: postFrom3.responsible_person,
                filing_production_range: postFrom3.production_range,
                filing_department: postFrom3.filing_department,
                certification_date: postFrom2.certification_date,
                certification_department: postFrom2.certification_department,
                effective_end_date: postFrom2.effective_end_date,
                effective_start_date: postFrom2.effective_start_date,
                product_factory_name: postFrom2.factory_name,
                product_legal_person: postFrom2.legal_person,
                license_number: postFrom2.license_number,
                product_production_address: postFrom2.production_address,
                product_production_range: postFrom2.production_range,
                productionlicense_file_name: postFrom2.productionlicense_file_name,
                productionlicense_key: postFrom2.productionlicense_key,
                product_residence: postFrom2.residence,
                product_responsible_person: postFrom2.responsible_person,
              }
              data = Object.assign({},data,info3)
            }
          // })
          // 提交接口
          this.$http.post(this.$api.factorySubmit, data, true).then(res => {
            this.putLogin = false
            this.subInfo = true
            this.$Message.success('提交成功')
            this.$router.push('/factoryManage')
          }).catch(err => {
            this.subInfo = true
          })
        })
        sessionStorage.setItem('updateList','0')
    }, 1000, true)
  }
}
</script>

<style scoped lang="less">
.marginRight18 {
  margin-right: 18px;
}
.page {
  background: #f0f0f0 !important;
  display: flex;
  flex-direction: column;
  .btmDiv {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 0px 25px 30px 25px;
    .formDiv {
      flex: 1;
      overflow-y: auto;
      margin-bottom: 30px;
    }
    .saveDiv {
      height: 36px;
      position: relative;
    }
  }
  .tabelDiv {
    flex: 1;
    border-radius: 0 8px 8px 8px;
    padding: 20px 0px;
    .saveDiv {
      height: 36px;
      position: relative;
    }
  }
  .topDiv {
    padding: 20px 25px 0 25px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    .form {
      .label {
        color: #000;
      }
    }
  }
  .topNavDiv {
    height: 42px;
    li {
      float: left;
      width: 120px;
      height: 42px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      border-radius: 3px 3px 0 0;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
.centeFont {
  margin: 0 10px;
}
.fontSize14px {
  font-size: 14px;
}
.noticeText{
  position: absolute;
  bottom: -20px;
  color: red;
  right: -20px;
  font-size: 14px;
}
</style>
