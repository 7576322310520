<template>
  <div class="content">
    <!--  基础信息 -->
    <div class="fromDiv">
      <!-- <span class="remarksAlink">湖北省药品监督管理局：<a target="_blank" href="http://fda.hubei.gov.cn/">http://fda.hubei.gov.cn/</a> 操作简要：点击进入网站-找到电子证书-在企业名称栏输入公司名称-查询，即可找到该公司的经营许可证/经营备案证书。</span> -->
      <span class="remarksAlink">国家药品监督管理局官网：<a target="_blank" href="https://www.nmpa.gov.cn/">https://www.nmpa.gov.cn/</a> 操作简要：点击进入网站-点击“医疗器械”-左侧国产器械（注册证号带有“准”）或进口器械（注册证号带有“进”），在快速查询里面输入需要查询的生产许可证/备案证号，然后打开生产许可证/备案证信息复制粘贴即可完成信息维护。</span>
      
      <div class="formLine">
        <FromLabel label="上传生产许可证" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <Input clearable readonly @on-clear="clearproductionlicenseFileName" :disabled="isBand" v-model="form.productionlicense_file_name" class="iviewIptWidth307 clearBtn" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件">
            <span slot="append" class="finger">
              <FormUpload label="上传" @get-key="getKey" :data="data"></FormUpload>
            </span>
          </Input>
          <!-- <span class="lookupImg" @click="see">{{ form.productionlicense_file_name }}</span> -->
          <span class="lookupImg nowrap" v-if="form.productionlicense_key && form.factory_name" @click="see">{{ form.factory_name }}-生产许可证</span>
          <span class="lookupImg nowrap" v-else-if="form.productionlicense_key && !form.factory_name" @click="see">-生产许可证</span>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="营业期限" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <DatePicker :disabled="isBand" @on-open-change="setTimet" :options="options2" @on-change="changeTime($event, 1)" :value="form.effective_start_date" format="yyyy-MM-dd" type="date" placeholder="请选择" class="iviewIptWidth170"></DatePicker>
          <span class="marginWords10"> 至 </span>
          <DatePicker :options="options" :disabled="isBand" @on-change="changeTime($event, 2)" :value="form.effective_end_date" format="yyyy-MM-dd" type="date" placeholder="请选择" class="iviewIptWidth170"></DatePicker>
        </div>
        <FromLabel label="许可证编号" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input maxlength="100" :disabled="isBand" v-model="form.license_number" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="企业名称" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input maxlength="100" :disabled="isBand || Boolean(form.is_approved)" v-model="form.factory_name" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="法人" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input maxlength="100" :disabled="isBand" v-model="form.legal_person" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="企业负责人" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input maxlength="100" :disabled="isBand" v-model="form.responsible_person" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone ">
        <FromLabel label="生产地址" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <Input maxlength="100" :disabled="isBand" v-model="form.production_address" class="iviewIptWidth1075px" placeholder="请输入..."></Input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <!-- <div class="newformLabel fl">
          <div>
            <span class="icon">*</span>
            <span>住所</span>
          </div>
        </div> -->
        <FromLabel label="住所" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input :disabled="isBand" type="text" maxlength="200" show-word-limit  v-model="form.residence" placeholder="请输入经营范围" class="iviewIptWidth1075px"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone moreHeight150">
        <div class="newformLabel fl newformLabelHeight">
          <div>
            <span class="icon">*</span>
            <span>生产范围</span>
          </div>
        </div>
        <!-- <FromLabel label="生产范围" :showIcon="true"></FromLabel> -->
        <div class="allWidth">
          <i-input maxlength="500" show-word-limit :disabled="isBand" type="textarea" :autosize="{ minRows: 3, maxRows: 3 }" v-model="form.production_range" placeholder="请输入" class="iviewIptWidth1075px"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="发证部门"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input maxlength="100" :disabled="isBand" v-model="form.certification_department" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="发证日期"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker
            :disabled="isBand"
            @on-change="
              e => {
                this.form.certification_date = e
              }
            "
            :value="form.certification_date"
            format="yyyy-MM-dd"
            type="date"
            placeholder="请选择"
            class="iviewIptWidth307"
          ></DatePicker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import FormUpload from '@/components/formUpload'
export default {
  name: 'productLicense',
  components: {
    FromLabel,
    FormUpload
  },
  data() {
    return {
      data: {
        table_type: 3
      },
      type: '',
      options2: {},
      license: [],
      options: {},
      isLoad: false,
      isBand: false,
      // 获取数据
      form: {
        subfactory_id: this.$route.query.id,
        factory_productlicense_id: '',
        productionlicense_file_name: '',
        productionlicense_key: '',
        license_number: '',
        factory_name: '',
        production_address: '',
        effective_start_date: '',
        effective_end_date: '',
        certification_date: '',
        production_range: '',
        certification_department: '',
        residence: '', // 住所
        legal_person: '', //	法人
        responsible_person: '' // 企业负责人
      },
      factory_productlicense_id: '',
      subfactory_id: this.$route.query.id
    }
  },
  props: ['datmain'],
  mounted() {
    if (this.$route.query.id) {
      this.data = {
        factory_id: this.$route.query.id,
        table_type: 3
      }
    } else {
      this.data = {
        table_type: 3
      }
    }
    // 事件中心 将 营业执照点击保存的 subfactory_id ,厂家名称，法人，地址，生产许可凭证 传递过来
    this.$root.eventHub.$off('sendDatatoProdut')
    this.$root.eventHub.$on('sendDatatoProdut', (subfactory_id, factoryName, legalPerson, Address, factory_productlicense_id) => {
      this.factoryName = factoryName
      if (factoryName != '') {
        this.form.factory_name = factoryName
      }
      if (legalPerson != '') {
        this.form.legal_person = legalPerson
      }
      // if (Address != '') {
      //   this.form.residence = Address
      // }
      if (subfactory_id != '') {
        this.form.subfactory_id = subfactory_id
      }
      this.factory_productlicense_id = factory_productlicense_id
    })
    this.$root.eventHub.$off('sendfactoryProductlicense')
    this.$root.eventHub.$on('sendfactoryProductlicense', data => {
      this.factory_productlicense_id = data[0]
      this.subfactory_id = data[1]
      this.judge()
    })
    this.judeIsband()
  },
  created() {},
  methods: {
    clearproductionlicenseFileName() {
      this.form.productionlicense_file_name = null
    },
    setTimet() {
      let that = this
      that.options2 = {
        disabledDate(date) {
          return date && date.valueOf() > new Date(that.$moment().format('YYYY-MM-DD'))
        }
      }
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.form.effective_start_date = e
        that.form.effective_end_date = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.form.effective_start_date)
          }
        }
      } else {
        this.form.effective_end_date = e
      }
    },
    judeIsband() {
      if (this.$route.query.status == '1') {
        this.isBand = true
      }
      if (this.$route.query.status == '0') {
        this.isBand = false
      }
    },
    judge() {
      if (this.subfactory_id !== undefined) {
        this.getProductData()
      } else {
      }
    },
    // 发送请求获取生产许可证
    getProductData() {
      this.form = {
        subfactory_id: this.$route.query.id,
        factory_productlicense_id: '',
        productionlicense_file_name: '',
        productionlicense_key: '',
        license_number: '',
        factory_name: '',
        production_address: '',
        effective_start_date: '',
        effective_end_date: '',
        certification_date: '',
        production_range: '',
        certification_department: '',
        residence: '', // 住所
        legal_person: '', //	法人
        responsible_person: '' // 企业负责人
      }
      if (this.factory_productlicense_id != null) {
        let data = {
          subfactory_id: this.subfactory_id,
          factory_productlicense_id: this.factory_productlicense_id
        }
        // 只有在有工厂id 和 factory_productlicense_id同时存在 的时候才发送请求
        this.$http.get(this.$api.factoryManageProductionlicense, data, false).then(res => {
          this.form = res.data
          this.form.effective_start_date = this.$moment.unix(this.form.effective_start_date).format('YYYY-MM-DD')
          this.form.effective_end_date = this.$moment.unix(this.form.effective_end_date).format('YYYY-MM-DD')
          this.form.certification_date = this.$moment.unix(this.form.certification_date).format('YYYY-MM-DD')
          if (this.form.effective_start_date == '1970-01-01') {
            this.form.effective_start_date = null
          }
          if (this.form.effective_end_date == '1970-01-01') {
            this.form.effective_end_date = null
          }
          if (this.form.certification_date == '1970-01-01') {
            this.form.certification_date = null
          }
        })
      }
    },
    see() {
      window.open(this.form.productionlicense_url)
    },
    // 上传按钮触发事件
    getKey(key, name, url) {
      this.form.productionlicense_key = key
      this.form.productionlicense_file_name = name
      // if (!this.form.factory_name) {
      //   this.form.productionlicense_file_name = ''
      // } else {
      //   this.form.productionlicense_file_name = this.form.factory_name
      // }
      this.form.productionlicense_url = url
      this.form = JSON.parse(JSON.stringify(this.form))
    },

    changePage(e) {},
    getBelongArea() {
      let data = {
        parent_id: '0'
      }
      this.$http.get(this.$api.zoneList, data, true).then(res => {
        this.companyprovinceList = res.data
      })
    }
  }
}
</script>

<style scoped lang="less">
.icon {
  font-size: 20px;
  height: 8px;
  width: 8px;
  position: absolute;
  left: 14px;
  top: 40px;
  color: #f73333;
}
.newformLabel {
  width: 187px;
  height: 100px;
  padding-left: 30px;
  background: #f8f8f9;
  border-right: 1px solid #e8eaec;
  position: relative;
  color: #525b6d;
  display: flex;
  align-items: center;
}
.newformLabelHeight {
  height: 150px;
}
.iviewIptWidth1075px {
  width: 1075px;
}

.moreHeight {
  height: 100px;
}
.imgstyle {
  width: 200px;
  height: 200px;
  img {
    width: 100%;
    height: 100%;
  }
}
.fontSize14px {
  font-size: 14px;
}
.content {
  padding-top: 10px;
  height: 100%;
  .fromDiv {
  }
}

.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.tableTitle {
  margin: 17px 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.centerUnit {
  margin: 0px 10px 0px 30px !important;
}
.imgBox {
  min-height: 110px;
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    li {
      position: relative;
      margin-right: 18px;
      display: inline-block;
      height: 102px;
      width: 171px;
      background-color: #d8d8d8;
      .normalImg {
        height: 100%;
        width: 100%;
      }
      .deleteImg {
        height: 22px;
        width: 22px;
        position: absolute;
        right: -11px;
        top: -11px;
        cursor: pointer;
      }
    }
  }
}
.addImg,
.removeImg {
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.addImg {
  margin: 0 14px 0 36px;
}
.afterWords10 {
  margin-left: 10px;
  color: #525b6d;
}
.marginWords10,
.singleCheckBox {
  color: #525b6d;
  margin: 0 10px;
}

.marginleft10px {
  // height: 100%;
  margin: 10px;
  // color: #fff;
  // background-color: #5ba5f0;
}
.marginleft20px {
  // height: 100%;
  margin-left: 20px;
  // color: #fff;
  // background-color: #5ba5f0;
}
.moreHeight150 {
  height: 150px;
}
.iviewIptWidth170 {
  width: 170px;
}
.clearBtn {
  /deep/.ivu-input-icon {
    position: absolute;
    top: 50%;
    right: 75px;
    transform: translate(50%, -50%);
  }
}
</style>
