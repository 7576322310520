<template>
  <div class="content">
    <!--  基础信息 -->
    <div class="fromDiv">
      <!-- <span class="remarksAlink">湖北省药品监督管理局：<a target="_blank" href="http://fda.hubei.gov.cn/">http://fda.hubei.gov.cn/</a> 操作简要：点击进入网站-找到电子证书-在企业名称栏输入公司名称-查询，即可找到该公司的经营许可证/经营备案证书。</span> -->
      <span class="remarksAlink">国家药品监督管理局官网：<a target="_blank" href="https://www.nmpa.gov.cn/">https://www.nmpa.gov.cn/</a> 操作简要：点击进入网站-点击“医疗器械”-左侧国产器械（注册证号带有“准”）或进口器械（注册证号带有“进”），在快速查询里面输入需要查询的生产许可证/备案证号，然后打开生产许可证/备案证信息复制粘贴即可完成信息维护。</span>
      <div class="formLine">
        <FromLabel label="备案凭证" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <Input :disabled="isBand" clearable readonly v-model="form.filing_file_name" class="iviewIptWidth307 clearBtn" @on-clear="clearfilingFileName" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件">
            <span slot="append" class="finger">
              <FormUpload label="上传" @get-key="getKey" :data="data"></FormUpload>
            </span>
          </Input>
          <!-- <span class="lookupImg" @click="see">{{ form.filing_file_name }}</span> -->
          <span class="lookupImg nowrap" v-if="form.filing_certificate_key && form.factory_name" @click="see">{{ form.factory_name }}-备案凭证</span>
          <span class="lookupImg nowrap" v-else-if="form.filing_certificate_key && !form.factory_name" @click="see">-备案凭证</span>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="备案号" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input :disabled="isBand" maxlength="100" v-model="form.filing_number" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="企业名称" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input :disabled="isBand || Boolean(form.is_approved)" maxlength="100" v-model="form.factory_name" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="法人" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input :disabled="isBand" maxlength="100" v-model="form.legal_person" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="企业负责人" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <Input :disabled="isBand" maxlength="100" v-model="form.responsible_person" class="iviewIptWidth307" placeholder="请输入..."></Input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <!-- <div class="newformLabel fl">
          <div>
            <span class="icon">*</span>
            <span>住所</span>
          </div>
        </div> -->
        <FromLabel label="住所" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input :disabled="isBand" type="text" maxlength="200" show-word-limit  v-model="form.residence" placeholder="请输入经营范围" class="iviewIptWidth1075px"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="生产地址" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <Input :disabled="isBand" v-model="form.production_address" class="iviewIptWidth1075px" placeholder="请输入..."></Input>
        </div>
      </div>
      <!--      <div class="formLine borderTopNone ">-->
      <!--        <FromLabel label="生产范围" :showIcon="true"></FromLabel>-->
      <!--        <div class="allWidth">-->
      <!--          <i-input v-model="form.production_range" placeholder="请输入" class="iviewIptWidth1075px"></i-input>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="formLine borderTopNone moreHeight">
        <div class="newformLabel fl">
          <div>
            <span class="icon">*</span>
            <span>生产范围</span>
          </div>
        </div>
        <div class="allWidth">
          <i-input :disabled="isBand" maxlength="500" show-word-limit type="textarea" :autosize="{ minRows: 2, maxRows: 2 }" v-model="form.production_range" placeholder="请输入" class="iviewIptWidth1075px"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="备案部门"></FromLabel>
        <div class="formFlIpt578 fl">
          <i-input :disabled="isBand" maxlength="100" v-model="form.filing_department" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
        <FromLabel label="备案日期" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker
            @on-change="
              e => {
                this.form.filing_date = e
              }
            "
            :disabled="isBand"
            :value="form.filing_date"
            format="yyyy-MM-dd"
            type="date"
            placeholder="请选择"
            class="iviewIptWidth307"
          ></DatePicker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import FormUpload from '@/components/formUpload'
export default {
  name: 'registrationCertificate',
  components: {
    FromLabel,
    FormUpload
  },
  data() {
    return {
      type: '',
      isBand: false,
      license: [],
      imgStatus: false,
      isLoad: false,
      typeList: [1],
      imgurl1: '',
      // 获取数据
      form: {
        filing_certificate_id: this.filing_certificate_id,
        factory_id: this.$route.query.id, // 工厂ID
        filing_file_name: '',
        filing_certificate_url: '',
        filing_certificate_key: '', // 备案凭证KEY
        filing_number: '', //	备案号
        factory_name: '', // 工厂姓名
        production_address: '', //	生产地址
        residence: '', // 住所
        legal_person: '', //	法人
        responsible_person: '', // 企业负责人
        production_range: '', // 生产范围
        filing_department: '', // 备案部门
        filing_date: '' // 备案日期
      },
      factory_id: this.$route.query.id,
      factoryName: '',
      // 存储备案凭证许可
      filing_certificate_id: '',
      data: {
        table_type: 4
      }
    }
  },
  props: ['datmain'],
  created() {},
  mounted() {
    if (this.$route.query.id) {
      this.data = {
        factory_id: this.$route.query.id,
        table_type: 4
      }
    } else {
      this.data = {
        table_type: 4
      }
    }
    // 事件中心获取值数
    this.$root.eventHub.$off('sendDatatoRegist')
    this.$root.eventHub.$on('sendDatatoRegist', (factory_id, factoryName, legalPerson, Address, filing_certificate_id) => {
      if (factoryName != '') {
        this.form.factory_name = factoryName
        this.factoryName = factoryName
      }
      if (legalPerson != '') {
        this.form.legal_person = legalPerson
      }
      // if (Address != '') {
      //   this.form.residence = Address
      // }
      if (factory_id != '') {
        this.factory_id = factory_id
      }
      this.filing_certificate_id = filing_certificate_id
    })
    this.$root.eventHub.$off('sendfactoryFiling')
    this.$root.eventHub.$on('sendfactoryFiling', data => {
      this.filing_certificate_id = data[0]
      this.factory_id = data[1]
      this.judge()
    })
    this.judeIsband()
  },
  methods: {
    judeIsband() {
      if (this.$route.query.status == '1') {
        this.isBand = true
      }
      if (this.$route.query.status == '0') {
        this.isBand = false
      }
    },
    clearfilingFileName() {
      this.form.filing_file_name = null
    },
    judge() {
      if (this.factory_id !== undefined) {
        this.getfilingcertificates()
      } else {
      }
    },
    // 获取厂家备案凭证信息
    getfilingcertificates() {
      this.form = {
        factory_id: this.$route.query.id, // 工厂ID
        filing_file_name: '',
        filing_certificate_key: '', // 备案凭证KEY
        filing_number: '', //	备案号
        factory_name: '', // 工厂姓名
        production_address: '', //	生产地址
        residence: '', // 住所
        legal_person: '', //	法人
        responsible_person: '', // 企业负责人
        production_range: '', // 生产范围
        filing_department: '', // 备案部门
        filing_date: '' // 备案日期
      }
      if (this.filing_certificate_id != null) {
        var data = {
          factory_id: this.factory_id,
          filing_certificate_id: this.filing_certificate_id
        }
        this.$http.get(this.$api.factoryManageFilingcertificate, data, false).then(res => {
          this.form = res.data
          this.form.filing_date = res.data.filing_date ? this.$moment.unix(res.data.filing_date).format('YYYY-MM-DD') : ''
        })
      }
    },
    changePage(e) {},
    // 下一步
    // nextstep() {
    //   let data = {
    //     filing_certificate_id: this.filing_certificate_id, // 备案凭证
    //     factory_id: this.factory_id || this.$route.query.id, // 工厂ID
    //     filing_file_name: this.form.filing_file_name,
    //     filing_certificate_key: this.form.filing_certificate_key, // 备案凭证KEY
    //     filing_number: this.form.filing_number, //	备案号
    //     factory_name: this.form.factory_name, // 工厂姓名
    //     production_address: this.form.production_address, //	生产地址
    //     residence: this.form.residence, // 住所
    //     legal_person: this.form.legal_person, //	法人
    //     responsible_person: this.form.responsible_person, // 企业负责人
    //     production_range: this.form.production_range, // 生产范围
    //     filing_department: this.form.filing_department, // 备案部门
    //     filing_date: this.form.filing_date // 备案日期
    //   }

    //   // ---------------表单对象提交为空检验-------------
    //   if (data.filing_date == '') {
    //     delete data.filing_date
    //   }
    //   if (this.factory_id == undefined) {
    //     delete data.factory_id
    //   }
    //   if (this.filing_certificate_id == '') {
    //     delete data.filing_certificate_id
    //   }
    //   // ---------------表单对象提交为空检验-------------

    //   // -----------------验证规则-------------------
    //   if (!data.filing_number) {
    //     this.$Message.warning('请填写备案号')
    //     return
    //   }
    //   if (!data.factory_name) {
    //     this.$Message.warning('请填写企业名称')
    //     return
    //   }
    //   if (!data.residence) {
    //     this.$Message.warning('请填写住所')
    //     return
    //   }
    //   if (!data.legal_person) {
    //     this.$Message.warning('请填写法人')
    //     return
    //   }

    //   if (!data.responsible_person) {
    //     this.$Message.warning('请填写负责人')
    //     return
    //   }
    //   if (!data.production_address) {
    //     this.$Message.warning('请填写生产地址')
    //     return
    //   }
    //   if (!data.production_range) {
    //     this.$Message.warning('请填写生产范围')
    //     return
    //   }
    //   if (!data.filing_date) {
    //     this.$Message.warning('请填写备案日期')
    //     return
    //   }
    //   // -----------------验证规则-------------------

    //   this.$http.post(this.$api.factoryManageFilingcertificate, data, true).then(res => {
    //     this.$Message.success('保存成功')
    //     this.$router.push('/factoryManage')
    //   })
    // },
    see() {
      window.open(this.form.filing_certificate_url)
    },
    getKey(key, name, url) {
      this.form.filing_certificate_key = key
      this.form.filing_file_name = name
      this.form.filing_certificate_url = url
      // if (!this.form.factory_name) {
      //   this.form.filing_file_name = ''
      // } else {
      //   this.form.filing_file_name = this.form.factory_name
      // }
      this.form = JSON.parse(JSON.stringify(this.form))
    }
  }
}
</script>

<style scoped lang="less">
.icon {
  font-size: 20px;
  height: 8px;
  width: 8px;
  position: absolute;
  left: 14px;
  top: 40px;
  color: #f73333;
}
.newformLabel {
  width: 187px;
  height: 100px;
  padding-left: 30px;
  background: #f8f8f9;
  border-right: 1px solid #e8eaec;
  position: relative;
  color: #525b6d;
  display: flex;
  align-items: center;
}

.moreHeight {
  height: 100px;
}
.iviewIptWidth1075px {
  width: 1075px;
}
.imgstyle {
  width: 200px;
  height: 200px;
  img {
    width: 100%;
    height: 100%;
  }
}
.fontSize14px {
  font-size: 14px;
}

.content {
  padding-top: 10px;
  height: 100%;
}

.topDiv {
  height: 36px;
  margin-bottom: 20px;
}
.marginRight10 {
  margin-right: 10px;
}
.tableTitle {
  margin: 17px 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.centerUnit {
  margin: 0px 10px 0px 30px !important;
}
.clearBtn {
  /deep/.ivu-input-icon {
    position: absolute;
    top: 50%;
    right: 75px;
    transform: translate(50%, -50%);
  }
}
.imgBox {
  min-height: 110px;
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    li {
      position: relative;
      margin-right: 18px;
      display: inline-block;
      height: 102px;
      width: 171px;
      background-color: #d8d8d8;
      .normalImg {
        height: 100%;
        width: 100%;
      }
      .deleteImg {
        height: 22px;
        width: 22px;
        position: absolute;
        right: -11px;
        top: -11px;
        cursor: pointer;
      }
    }
  }
}
.addImg,
.removeImg {
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.addImg {
  margin: 0 14px 0 36px;
}
.afterWords10 {
  margin-left: 10px;
  color: #525b6d;
}
.marginWords10,
.singleCheckBox {
  color: #525b6d;
  margin: 0 10px;
}

.marginleft10px {
  // height: 100%;
  margin-left: 10px;
  // color: #fff;
  // background-color: #5ba5f0;
}
.marginleft20px {
  // height: 100%;
  margin-left: 20px;
  // color: #fff;
  // background-color: #5ba5f0;
}
</style>
