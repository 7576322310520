<template>
  <div class="content">
    <!--  基础信息 -->
    <div class="fromDiv">
      <div class="topDiv">
        <a class="longTextBtn finger fl btnReset marginRight18" @click="down"> 模版下载 </a>
        <span class="fl longTextBtn finger btnReset" v-if="apiSrc">
          <FormUploadFile label="模板导入" :name="name" :multiple="false" :apiSrc="apiSrc" @get-data="getUploadData"></FormUploadFile>
        </span>
      <span class="remarksAlink">企查查官网：<a href="https://www.qcc.com/" target="_blank">https://www.qcc.com/</a> 操作简要：点击进入网站-在查询栏输入公司名称-找到该公司的营业执照信息，复制粘贴即可完成信息维护。</span>

      </div>
      <div class="formLine">
        <FromLabel label="营业执照" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <Input maxlength="5" clearable readonly :disabled="isBand" v-model="form.businesslicense_file_name" class="iviewIptWidth307 clearBtn" @on-clear="clearbusinesslicenseFileName" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件">
            <span slot="append" class="finger">
              <FormUpload label="上传" @get-key="getKey" :data="data1"></FormUpload>
            </span>
          </Input>
          <!-- <span class="lookupImg nowrap" @click="see">{{ form.businesslicense_file_name }}</span> -->
          <span class="lookupImg nowrap" v-if="form.businesslicense_key && form.factory_name" @click="see">{{ form.factory_name }}-营业执照</span>
          <span class="lookupImg nowrap" v-else-if="!form.factory_name && form.businesslicense_key" @click="see">-营业执照</span>
        </div>
        <FromLabel label="上传合同"></FromLabel>
        <div class="formFrIpt fl">
          <Input clearable readonly :disabled="isBand" v-model="form.contract_file_name" class="iviewIptWidth307 clearBtn" @on-clear="clearbusinesscontractFileName" placeholder="仅支持jpg.jepg.png.pdf等格式的图片/文件">
            <span slot="append" class="finger">
              <FormUpload label="上传" @get-key="getKey2" :data="data2"></FormUpload>
            </span>
          </Input>
          <span class="lookupImg nowrap" v-if="form.contract_key && form.factory_name" @click="see2">{{ form.contract_file_name }}-合同</span>
          <span class="lookupImg nowrap" v-else-if="form.contract_key && !form.factory_name" @click="see2">-合同</span>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="公司名称" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <!-- @on-change="selectProduct" -->
          <Select :disabled="isBand||form.is_approved === '1'" class="iviewIptWidth307" placeholder="请输入/选择公司名称" v-model="form.factory_name" filterable clearable allow-create ref="factory_name" @on-create="handleCreate" @on-change="selectFactory" @on-open-change="openChange">
            <Option v-for="(item, index) in FactoryList" :value="item.name" :key="index">{{ item.name }}</Option>
          </Select>
          <span class="padding5px">(请先检索库内是否有该厂家，如有可直接点击自动同步)</span>
        </div>
        <FromLabel label="统一社会信用代码" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input maxlength="100" :disabled="isBand" v-model="form.social_credit_code" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="类型"></FromLabel>
        <div class="allWidth">
          <Select :disabled="true" v-model="form.factory_type" class="iviewIptWidth165">
            <Option v-for="(item, index) in typeList" :key="index" :value="item.id">{{ item.type }}</Option>
          </Select>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <!-- <div class="newformLabel fl">
          <div>
            <span class="icon">*</span>
            <span>住所</span>
          </div>
        </div> -->
        <FromLabel label="住所" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input :disabled="isBand" type="text" maxlength="200" show-word-limit :autosize="{ minRows: 1, maxRows: 1 }" v-model="form.detailed_address" placeholder="请输入住所" class="iviewIptWidth1075px"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="法人" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <i-input maxlength="100" :disabled="isBand" v-model="form.legal_person" placeholder="请输入" class="iviewIptWidth307"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="成立日期"></FromLabel>
        <div class="formFlIpt578 fl">
          <DatePicker
            :disabled="isBand"
            :transfer="true"
            @on-change="
              e => {
                this.form.setup_date = e
              }
            "
            :value="form.setup_date"
            format="yyyy-MM-dd"
            type="date"
            placeholder="请选择"
            class="iviewIptWidth307"
          ></DatePicker>
        </div>
        <FromLabel label="注册资本" :showIcon="true"></FromLabel>
        <div class="formFrIpt fl">
          <i-input type="number" :min="0" :disabled="isBand" v-model="form.registered_capital" placeholder="请输入" class="iviewIptWidth307"></i-input>
          <span class="afterWords10"> 万元</span>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="营业期限" :showIcon="true"></FromLabel>
        <div class="formFlIpt578 fl">
          <DatePicker clearable :disabled="isBand" :options="options2" :transfer="true" @on-open-change="setTimet" @on-change="changeTime($event, 1)" :value="form.business_start" format="yyyy-MM-dd" type="date" placeholder="请选择" class="iviewIptWidth170"></DatePicker>
          <span class="marginWords10"> 至 </span>
          <DatePicker clearable :disabled="islongterm" :transfer="true" @on-change="changeTime($event, 2)" :value="form.business_end" :options="options" format="yyyy-MM-dd" type="date" placeholder="请选择" class="iviewIptWidth170"></DatePicker>
          <Checkbox @on-change="longtermChange" :disabled="isBand" true-value="1" false-value="0" v-model="form.is_long_term" class="singleCheckBox">长期</Checkbox>
        </div>
        <FromLabel label="登记日期"></FromLabel>
        <div class="formFrIpt fl">
          <DatePicker
            :disabled="isBand"
            :transfer="true"
            @on-change="
              e => {
                this.form.registered_date = e
              }
            "
            @on-open-change="setTimet"
            :options="options2"
            :value="form.registered_date"
            format="yyyy-MM-dd"
            type="date"
            placeholder="请选择"
            class="iviewIptWidth307"
          ></DatePicker>
        </div>
      </div>
      <div class="formLine borderTopNone moreHeight150">
        <div class="newformLabel fl newformLabelHeight">
          <div>
            <span class="icon">*</span>
            <span>经营范围</span>
          </div>
        </div>
        <!-- <FromLabel label="经营范围" :showIcon="true"></FromLabel> -->
        <div class="allWidth">
          <i-input maxlength="500" show-word-limit :disabled="isBand" type="textarea" :autosize="{ minRows: 3, maxRows: 3 }" v-model="form.business_range" placeholder="请输入经营范围" class="iviewIptWidth1075px"></i-input>
        </div>
      </div>
      <div class="formLine borderTopNone">
        <FromLabel label="资料维护" :showIcon="true"></FromLabel>
        <div class="allWidth">
          <Checkbox :disabled="isBand" true-value="1" false-value="0" v-model="form.product" class="singleCheckBox">生产许可证</Checkbox>
          <Checkbox :disabled="isBand" true-value="1" false-value="0" v-model="form.fill" class="singleCheckBox">备案凭证</Checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FromLabel from '@/components/formLabel'
import FormUpload from '@/components/formUpload'
import FormUploadFile from '@/components/formUploadFile'
import http from '@/service/baseUrl'
import { mapState } from 'vuex'
export default {
  name: 'businessLicenseInfo',
  components: {
    FromLabel,
    FormUpload,
    FormUploadFile
  },
  data() {
    return {
      data1: {},
      data2: {},
      ImportantId: null,
      // 控制图片显示
      imgStatus: false,
      imgStatus2: false,
      loading: false,
      license: [],
      typeList: [
        { id: '1', type: '国内厂家' },
        { id: '2', type: '境外厂家' }
      ],
      imgurl1: '',
      imgurl2: '',
      // 厂家列表
      FactoryList: [],
      // 控制是否是供应商
      issuply: false,
      form: {
        // factory_id: this.$route.query.id,
        factory_id: null,
        name: null, //	工厂姓名
        businesslicense_file_name: null,
        contract_file_name: null,
        businesslicense_key: null, // 营业执照Key
        contract_key: null, // 合同KEY
        social_credit_code: null, // 统一社会信用代码
        is_supplier: 0, // 	是否为供应商 是1 否0
        province: 0,
        city: 0, // 地址ID
        detailed_address: null, // 详情地址
        legal_person: null, // 法人
        setup_date: null, // 成立日期
        registered_date: null, // 成立日期
        registered_capital: 0, // 注册资本
        business_start: null, // 起始营业期限
        business_end: null, // 截止营业期限
        business_range: null, // 经营范围
        fill: 0,
        product: 0,
        factory_type: null, // 厂家类型 1=国内厂家 2=境外厂家 0=未知
        // factory_state: '', // 厂家状态 1=有效 2=新增 3=修改 4=过期 5=近效期 6=审核驳回 0=禁用
        data_maintenance: null // 维护状态
      },
      contract: '',
      LicenseName: '',
      // 工厂id
      factory_id: this.$route.query.id,
      getfactory_id: null,
      isBand: false,
      islongterm: false,
      options: {},
      options2: {},
      // 模版地址
      apiSrc: '',
      name: 'xlsx_file',
      changeName: ''
    }
  },
  created() {
    if (this.$route.query.id) {
      this.data1 = {
        factory_id: this.$route.query.id,
        table_type: 1
      }
      this.data2 = {
        factory_id: this.$route.query.id,
        table_type: 2
      }
    } else {
      this.data1 = {
        table_type: 1
      }
      this.data2 = {
        table_type: 2
      }
    }
    this.apiSrc = http.http + this.$api.factoryImport.url
  },
  mounted() {
    this.form.factory_type = localStorage.getItem('facttype')
    this.$root.eventHub.$off('sendIDToReg')
    this.$root.eventHub.$on('sendIDToReg', ImportantId => {
      this.ImportantId = ImportantId
    })
    this.$http.get(this.$api.getfactoryList, false).then(res => {
      this.FactoryList = res.data
    })
    this.judge()
    this.judeIsband()
  },
  computed: {
    ...mapState(['productInfo'])
  },
  methods: {
    longtermChange(e) {
      if (e === '1') {
        this.islongterm = true
        this.form.business_end = null
      }
      if (e === '0') {
        this.islongterm = false
      }
    },
    clearbusinesslicenseFileName() {
      this.form.businesslicense_file_name = null
    },
    clearbusinesscontractFileName() {
      this.form.contract_file_name = null
    },
    down() {
      this.$http.downFile(this.$api.factoryTemlpateDoenload, null, true).then(res => {
        let csvData = new Blob([res.data], { type: 'application/x-xlsx' }) // response.data要导出的内容
        let file_name = '厂家导入模版' + '.xls'
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(csvData, file_name)
        } else {
          var a = document.createElement('a')
          var url = window.URL.createObjectURL(csvData)
          a.href = url
          a.download = file_name
          a.click()
          a.remove()
          window.URL.revokeObjectURL(url)
        }
      })
    },
    // 模版导入
    getUploadData(data) {
      this.$Message.success('文件导入成功!')
      this.$emit('allform', data)
    },
    setTimet() {
      let that = this
      that.options2 = {
        disabledDate(date) {
          return date && date.valueOf() > new Date(that.$moment().format('YYYY-MM-DD'))
        }
      }
    },
    // 时间改变
    changeTime(e, num) {
      if (num == 1) {
        let that = this
        that.form.business_start = e
        that.form.business_end = ''
        that.options = {
          disabledDate(date) {
            return date && date.valueOf() < new Date(that.form.business_start)
          }
        }
      } else {
        this.form.business_end = e
      }
    },
    judeIsband() {
      if (this.$route.query.status == '1') {
        this.isBand = true
        this.islongterm = true
      }
      if (this.$route.query.status == '0') {
        this.isBand = false
        this.islongterm = false
      }
    },
    judge() {
      if (this.factory_id !== undefined) {
        this.getFactoryInfo()
      } else {
      }
    },
    openChange(status) {
      if (!status && !this.form.factory_name) {
        this.$refs.factory_name.query = ''
      }
    },
    // 下拉 选择厂家触发事件
    selectFactory(e) {
      if (!e) return
      this.FactoryList.forEach((item, index) => {
        if (item.name == e) {
          this.subfactoryIIIDDD = item.subfactory_id
        }
      })
      if (this.subfactoryIIIDDD == undefined) {
        return
      }
      this.$emit('sendsubfactoryID', this.subfactoryIIIDDD)
      let data = {
        subfactory_id: this.subfactoryIIIDDD
      }
      this.$http.get(this.$api.factorySubmit, data, true).then(res => {
        this.$emit('pull', true)
        // 将下拉数据赋值给 营业执照
        this.form = res.data[0]
        this.form.business_end = this.$moment.unix(res.data[0].business_end).format('YYYY-MM-DD')
        this.form.business_start = this.$moment.unix(res.data[0].business_start).format('YYYY-MM-DD')
        this.form.setup_date = this.$moment.unix(res.data[0].setup_date).format('YYYY-MM-DD')
        this.form.registered_date = this.$moment.unix(res.data[0].registered_date).format('YYYY-MM-DD')
        if (this.form.business_end == '1970-01-01') {
          this.form.business_end = null
        }
        if (this.form.business_start == '1970-01-01') {
          this.form.business_start = null
        }
        if (this.form.setup_date == '1970-01-01') {
          this.form.setup_date = null
        }
        if (this.form.registered_date == '1970-01-01') {
          this.form.registered_date = null
        }
        this.$emit('senddatmain', res.data[0].data_maintenance)
        if (res.data[0].data_maintenance == '1') {
          this.$emit('sendProduct', res.data[1])
        }
        if (res.data[0].data_maintenance == '2') {
          this.$emit('sendFillForm', res.data[2])
        }
        if (res.data[0].data_maintenance == '3') {
          this.$emit('sendProduct', res.data[1])
          this.$emit('sendFillForm', res.data[2])
        }
        // 选择了就不允许再修改了
        this.form.is_approved = res.data[3].is_approved
      })
    },
    clear(e) {
      this.subfactoryIIIDDD = null
      this.form.factory_name = null
    },
    // 新增厂家
    handleCreate(val) {
      this.FactoryList.push({
        name: val
      })
    },
    // 查看 营业执照
    see() {
      window.open(this.form.businesslicense_url)
    },
    // 查看 营业执照
    see2() {
      window.open(this.form.contract_url)
    },
    // 上传按钮触发事件
    getKey(key, name, url) {
      this.form.businesslicense_key = key
      this.form.businesslicense_file_name = name
      // if (!this.form.factory_name) {
      //   this.form.businesslicense_file_name = ''
      // } else {
      //   this.form.businesslicense_file_name = this.form.factory_name
      // }
      this.form.businesslicense_url = url
      this.form = JSON.parse(JSON.stringify(this.form))
    },

    getKey2(key, name, url) {
      this.form.contract_key = key
      this.form.contract_file_name = name
      // if (!this.form.factory_name) {
      //   this.form.contract_file_name = ''
      // } else {
      //   this.form.contract_file_name = this.form.factory_name
      // }
      this.form.contract_url = url
      this.form = JSON.parse(JSON.stringify(this.form))
    },
    // 获取去工厂信息
    getFactoryInfo() {
      // 在存在路由的时候发送请求
      let data = {
        factory_id: this.factory_id
      }
      if (data.factory_id != '' && data.factory_id != undefined) {
        // 获取厂家列表
        this.$http
          .get(this.$api.getfactoryList, false)
          .then(res => {
            this.FactoryList = res.data
          })
          .then(res => {
            this.$http.get(this.$api.factoryManageFactoryIsnformation, data, true).then(res => {
              this.$emit('sendsubfactory_id', res.data.subfactory_id)
              // 赋值给form
              if (!this.FactoryList.includes(res.data.factory_name)) {
                this.FactoryList.push({
                  name: res.data.factory_name
                })
              }
              this.form = res.data
              if (this.form.product == '1' && this.form.fill == '1') {
                this.form.data_maintenance = '3'
                this.$emit('senddatmain', '3')
              }
              if (this.form.product == '0' && this.form.fill == '1') {
                this.form.data_maintenance = '2'
                this.$emit('senddatmain', '2')
              }
              if (this.form.product == '1' && this.form.fill == '0') {
                this.form.data_maintenance = '1'
                this.$emit('senddatmain', '1')
              }

              // 传递数值
              this.$root.eventHub.$emit('sendfactoryFiling', [res.data.filing_certificate_id, data.factory_id])
              this.$root.eventHub.$emit('sendfactoryProductlicense', [res.data.factory_productlicense_id, data.factory_id])

              this.$emit('sendProduct', res.data.product)
              this.$emit('sendFill', res.data.fill)
              // 传出去

              if (this.form.is_long_term == null) {
                this.form.is_long_term = '0'
                this.islongterm = false
              }
              if (this.form.is_long_term == '1') {
                this.islongterm = true
              }
              // 处理时间
              this.form.business_end = this.$moment.unix(res.data.business_end).format('YYYY-MM-DD')
              this.form.business_start = this.$moment.unix(res.data.business_start).format('YYYY-MM-DD')
              this.form.setup_date = this.$moment.unix(res.data.setup_date).format('YYYY-MM-DD')
              this.form.registered_date = this.$moment.unix(res.data.registered_date).format('YYYY-MM-DD')

              // ---------添加时间判断规则----------
              if (this.form.business_end == '1970-01-01') {
                this.form.business_end = ''
              }
              if (this.form.business_start == '1970-01-01') {
                this.form.business_start = ''
              }
              if (this.form.setup_date == '1970-01-01') {
                this.form.setup_date = ''
              }
              if (this.form.registered_date == '1970-01-01') {
                this.form.registered_date = ''
              }
              // ---------添加时间判断规则----------
            })
          })
      }
    }
  },
  watch: {}
}
</script>

<style scoped lang="less">
.marginRight18 {
  margin-right: 18px;
}
.icon {
  font-size: 20px;
  height: 8px;
  width: 8px;
  position: absolute;
  left: 14px;
  top: 40px;
  color: #f73333;
}
.newformLabel {
  width: 187px;
  height: 100px;
  padding-left: 30px;
  background: #f8f8f9;
  border-right: 1px solid #e8eaec;
  position: relative;
  color: #525b6d;
  display: flex;
  align-items: center;
}
.newformLabelHeight{
  height: 150px;
}
.imgstyle {
  width: 200px;
  height: 200px;
  img {
    width: 100%;
    height: 100%;
  }
}
.iviewIptWidth1075px {
  width: 1075px;
}
.clearBtn {
  /deep/.ivu-input-icon {
    position: absolute;
    top: 50%;
    right: 75px;
    transform: translate(50%, -50%);
  }
}
.content {
  height: 80%;
  .fromDiv {
    position: relative;
    height: 100%;
  }
}

.fontSize14px {
  font-size: 14px;
}
.iviewIptWidth170 {
  width: 170px;
}
.content {
  padding: 0px 30px;
}
.topDiv {
  display: flex;
  align-items: center;
  height: 36px;
  margin: 20px 0;
}
.marginRight10 {
  margin-right: 10px;
}
.tableTitle {
  margin: 17px 0 20px 0;
  .title {
    color: #525b6d;
    font-size: 18px;
    font-weight: 600;
  }
}
.centerUnit {
  margin: 0px 10px 0px 30px !important;
}
.imgBox {
  min-height: 110px;
  ul {
    height: 100%;
    width: 100%;
    padding: 0;
    li {
      position: relative;
      margin-right: 18px;
      display: inline-block;
      height: 102px;
      width: 171px;
      background-color: #d8d8d8;
      .normalImg {
        height: 100%;
        width: 100%;
      }
      .deleteImg {
        height: 22px;
        width: 22px;
        position: absolute;
        right: -11px;
        top: -11px;
        cursor: pointer;
      }
    }
  }
}
.addImg,
.removeImg {
  height: 22px;
  width: 22px;
  cursor: pointer;
}
.addImg {
  margin: 0 14px 0 36px;
}
.afterWords10 {
  margin-left: 10px;
  color: #525b6d;
}
.marginWords10,
.singleCheckBox {
  color: #525b6d;
  margin: 0 10px;
}

.marginleft10px {
  // height: 100%;
  margin: 10px;
  // color: #fff;
  // background-color: #5ba5f0;
}
.marginleft20px {
  // height: 100%;
  margin-left: 20px;
  // color: #fff;
  // background-color: #5ba5f0;
}
.iviewidth700px {
  width: 700px;
}

.moreHeight {
  height: 100px;
}
.moreHeight150 {
  height: 150px;
}
.remarksAlink {
  margin-left: 20px;
}
</style>
